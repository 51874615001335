<template>
  <div class="container">
    <img src="/img/img1b.png" alt="" class="bg1" />
    <section class="first" id="home">
      <img class="bg" src="/img/img1c.png" alt="" />
      <div class="inner">
        <el-row :gutter="20">
          <el-col class="box2" :span="12" :xs="24">
            <h1>AI enhanced DeFi Asset Management</h1>
            <p class="mbh">
              Manage your DeFi Asset with AI-Powered Decentralized<br />
              Asset Management for greater transparency, enhenced<br />
              security, and increased accessibility.
            </p>
            <p class="pch">
              Manage your DeFi Asset with AI-Powered Decentralized Asset
              Management for greater transparency, enhenced security, and
              increased accessibility.
            </p>
            <div class="box3">
              <a
                href="https://app.sypool.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="btn1">Launch App</div>
              </a>
              <a class="play" href="#introduction">
                <img src="/img/play.png" alt="play" />
              </a>
            </div>
          </el-col>
          <el-col class="box5" :span="11">
            <img src="/img/img1a.png" alt="" />
          </el-col>
        </el-row>
      </div>
    </section>

    <section class="second" id="features">
      <el-row :gutter="50">
        <el-col :span="11" :xs="24">
          <h3>Features</h3>
          <h2>Best features provided by Sypool</h2>
          <p class="text5">
            Sypool is an on-chain asset management protocol aimed at lowering
            the barriers to entry for users and providing professional financial
            services.
          </p>
          <div class="box4">
            <a
              href="https://app.sypool.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="btn3">Launch App</div>
            </a>
            <a
              href="https://sypool-protocol.gitbook.io/welcome-to-sypool/ai-tradegenius/public-indicators/momentum-eruption"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="btn4">Learn more</div>
            </a>
          </div>
        </el-col>
        <el-col :span="12" :xs="24">
          <el-row>
            <el-col :span="11" class="item1 wow animate__fadeInUp" :xs="12">
              <h4>Tokenization</h4>
              <img src="/img/dollar.png" alt="Tokenization" />
              <p>
                Tokenized<br />
                Fund Shares
              </p>
            </el-col>
            <el-col
              :span="11"
              :offset="1"
              class="item1 wow animate__fadeInUp"
              :xs="{ span: 12, offset: 0 }"
            >
              <h4>Profession</h4>
              <img src="/img/pro.png" alt="Profession" />
              <p>
                Qualified and Sophisticated<br />
                Fund Managers
              </p>
            </el-col>
            <el-col :span="11" class="item1 wow animate__fadeInUp" :xs="12">
              <h4>AI Optimization</h4>
              <img src="/img/robot.png" alt="AI Optimization" />
              <p>
                Quantitative<br />
                Trading with AI
              </p>
            </el-col>
            <el-col
              :span="11"
              :offset="1"
              class="item1 wow animate__fadeInUp"
              :xs="{ span: 12, offset: 0 }"
            >
              <h4>Multi Chains</h4>
              <img src="/img/multi.png" alt="Multi Chains" />
              <p>
                Support Assets<br />
                on Multi Chains
              </p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </section>

    <section class="third" id="introduction">
      <h3>About us</h3>
      <h2>What is Sypool</h2>
      <div class="video">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/HKwV6CiVRjs"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>

    <section class="fourth">
      <h3>Bi-token</h3>
      <h2>
        Governance Token SYP<br />
        and Shares Token SAP
      </h2>
      <el-row class="box41">
        <el-col class="item4 wow animate__fadeInUp" :span="11" :xs="24">
          <img src="/img/logo-dp.png" alt="" />
          <p>
            SYP—the governance token, providing staking<br />
            gains, voting power, and community rewards.
          </p>
        </el-col>
        <el-col
          class="item4 wow animate__fadeInUp"
          :span="11"
          :offset="2"
          :xs="{ span: 24, offset: 0 }"
        >
          <img src="/img/logo-wp.png" alt="" />
          <p>
            SAP—tokenized fund shares, representing<br />
            partial ownership of a single pool.
          </p>
        </el-col>
      </el-row>
    </section>

    <section class="fivth mbh">
      <el-row class="inner" :gutter="93">
        <el-col :span="13">
          <h1>
            Try AI-TradeGenius on Sypool<br />
            <br />
            Happy Trading!
          </h1>
          <a
            href="https://www.tradingview.com/u/Sypool/#published-scripts"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="btn51">Follow us on TradingView</div>
          </a>
          <a
            href="https://www.tradingview.com/u/Sypool/#published-scripts"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="btn52">Subscribe the Indicators</div>
          </a>
        </el-col>
        <el-col :span="11">
          <img class="wow animate__fadeInUp" src="/img/img51.png" alt="" />
          <img class="wow animate__fadeInUp" src="/img/img52.png" alt="" />
          <img class="wow animate__fadeInUp" src="/img/img53.png" alt="" />
        </el-col>
      </el-row>
    </section>

    <section class="fivth pch">
      <div class="inner">
        <h1>
          Try AI-TradeGenius on Sypool<br />
          <br />
          Happy Trading!
        </h1>
        <div class="box53">
          <img class="wow animate__fadeInUp" src="/img/img51.png" alt="" />
          <img class="wow animate__fadeInUp" src="/img/img52.png" alt="" />
          <img class="wow animate__fadeInUp" src="/img/img53.png" alt="" />
        </div>
        <a
          href="https://www.tradingview.com/u/Sypool/#published-scripts"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="btn51">Follow us on TradingView</div>
        </a>
        <a
          href="https://www.tradingview.com/u/Sypool/#published-scripts"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="btn52">Subscribe the Indicators</div>
        </a>
      </div>
    </section>

    <section class="sixth" id="community">
      <h3>Partners and Investors</h3>
      <h2>Backed by</h2>
      <div class="box61">
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/blockwall.png" class="grays" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/skyvision.png" class="grays" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/bella.png" class="grays" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/evernew.png" class="grays" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/kronos.png" class="grays" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/woo.png" class="grays" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/NGC.png" class="grays" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/pyth.png" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/raydiu.png" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/coingecko.png" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/img/coinmarket.png" alt />
        </span>
        <span class="wow animate__fadeInUp">
          <img src="../assets/buysyp/mexc.png" alt />
        </span>
      </div>
    </section>

    <section class="seventh" id="docs">
      <h1>
        Manage your Defi<br />
        Asset with Sypool
      </h1>
      <a href="https://app.sypool.io" target="_blank" rel="noopener noreferrer">
        <div class="btn1">Launch App</div>
      </a>
      <img src="/img/img7a.png" alt="" class="img7a mbh" />
    </section>

    <div class="bg2">
      <img src="/img/img7b.png" alt="" class="img7b" />
      <img src="/img/img7c.png" alt="" class="img7c" />
    </div>
    <div class="bg3">
      <img src="/img/bg3.png" alt="" />
    </div>
    <div class="bg4">
      <img src="/img/bg3.png" alt="" />
    </div>
  </div>
</template>

<script>
const { WOW } = require("wowjs");

export default {
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      const width = window.visualViewport.width;
      if (width > 768) {
        new WOW({ animateClass: "animate__animated" }).init();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: relative;
}

h2 {
  margin-top: 1.8rem;
  margin-bottom: 4.5rem;
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 3.5rem;
}

h3 {
  font-size: 1.8rem;
  font-weight: 400;
  color: #fff;
  line-height: 2.6rem;
}

p {
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
  line-height: 3rem;
}

.grays {
  -webkit-filter: grayscale(100%) brightness(10000%);
  filter: grayscale(100%) brightness(10000%);
}

.bg1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .bg1 {
    height: 100vh;
    object-fit: cover;
  }
  h2 {
    margin-top: 0.5rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.first {
  position: relative;
  height: 95rem;
  overflow: hidden;
  .bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .inner {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    height: 54.5rem;
    padding: 22rem 27rem;
    box-sizing: border-box;
  }
  h1 {
    font-size: 4.4rem;
    font-weight: 700;
    line-height: 6.4rem;
  }
  .box2 {
    p {
      margin-top: 3.7rem;
      width: 65rem;
      font-size: 2rem;
      font-weight: 400;
      line-height: 4rem;
      letter-spacing: 1px;
    }
  }
  .box3 {
    margin-top: 6rem;
    display: flex;
    .btn1 {
      height: 6.4rem;
      padding: 0 5.4rem;
      background: linear-gradient(
        161.7deg,
        rgb(7, 247, 189) 8.143%,
        rgb(7, 247, 239) 67.516%
      );
      border-radius: 9999px;
      font-size: 2rem;
      font-weight: 400;
      line-height: 6.4rem;
      text-align: center;
      color: rgb(38, 35, 35);
    }
    .play {
      margin-left: 6rem;
      display: block;
      height: 6.4rem;
      width: 6.4rem;
      cursor: pointer;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .box5 {
    height: 54.5rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 768px) {
  .first {
    height: 70vh;
    .inner {
      padding: 7rem 2rem 2rem;
      height: unset;
    }
    h1 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    .box2 {
      p {
        margin-top: 2rem;
        width: unset;
        font-size: 1rem;
        line-height: 1.3rem;
      }
    }
    .box3 {
      .btn1 {
        height: unset;
        padding: 1rem 1rem;
        font-size: 1rem;
        line-height: 1rem;
      }
      .play {
        margin-left: 2rem;
        height: 3rem;
        width: 3rem;
      }
    }
    .box5 {
      display: none;
    }
  }
}

.second {
  margin-top: 15rem;
  padding: 0 27rem;
  .text5 {
    width: 60rem;
  }
  .box4 {
    margin-top: 5rem;
    display: flex;
  }
  .btn3 {
    padding: 0 5.4rem;
    height: 6.4rem;
    background: linear-gradient(
      161.7deg,
      rgb(7, 247, 189) 8.145%,
      rgb(7, 247, 239) 67.514%
    );
    border-radius: 9999px;
    font-size: 2rem;
    font-weight: 400;
    line-height: 6.4rem;
    text-align: center;
    color: rgb(38, 35, 35);
  }
  .btn4 {
    display: inline-block;
    padding: 0 5.4rem;
    height: 6.4rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 6.4rem;
    text-align: center;
    color: #fff;
  }
  .item1 {
    margin-bottom: 4.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      color: rgb(255, 255, 255);
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.6rem;
      text-align: center;
    }
    img {
      margin: 2rem 0;
      display: block;
      height: 10.4rem;
    }
    p {
      color: rgb(255, 255, 255);
      font-size: 1.8rem;
      line-height: 2.6rem;
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .second {
    margin-top: 4rem;
    padding: 0 2rem;
    .text5 {
      width: unset;
    }
    .btn3 {
      height: unset;
      padding: 1rem 1rem;
      font-size: 1rem;
      line-height: 1rem;
    }
    .btn4 {
      margin-left: 2rem;
      height: unset;
      padding: 1rem 1rem;
      font-size: 1rem;
      line-height: 1rem;
    }
    .item1 {
      margin-top: 3rem;
      margin-bottom: 0;
      h4 {
        font-size: 1rem;
        line-height: 1.2rem;
      }
      img {
        height: 6rem;
      }
      p {
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
}

.third {
  margin-top: 15rem;
  padding: 0 27rem;
  .video {
    height: 65rem;
    border-radius: 5rem;
    overflow: hidden;
    background-color: #101213;
  }
}

@media screen and (max-width: 768px) {
  .third {
    margin-top: 4rem;
    padding: 0 2rem;
    .video {
      height: unset;
      border-radius: 1rem;
    }
  }
}

.fourth {
  margin-top: 15rem;
  padding: 0 27rem;
  .box41 {
    margin-top: 10rem;
  }
  .item4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem 1rem;
    img {
      margin-bottom: 7rem;
      width: 15rem;
      height: 15rem;
      object-fit: contain;
    }
    p {
      font-size: 2rem;
      font-weight: 400;
      color: #fff;
      line-height: 2.9rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .fourth {
    margin-top: 4rem;
    padding: 0 2rem;
    .box41 {
      margin-top: 0;
    }
    .item4 {
      padding: 2rem 1rem;
      img {
        height: 3rem;
        width: 3rem;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1rem;
        line-height: 1.2rem;
        text-align: center;
      }
    }
  }
}

.fivth {
  margin-top: 15rem;
  padding: 0 35rem;
  .inner {
    padding: 6.4rem 7.3rem 6.4rem 2.3rem;
    background: #3b3d3e;
    border-radius: 4rem;
  }
  h1 {
    margin-top: 12.8rem;
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    line-height: 4.3rem;
  }
  .btn51 {
    margin-top: 13.9rem;
    width: 38rem;
    height: 7.6rem;
    border-radius: 9999px;
    background: rgb(103, 104, 103);
    font-size: 2rem;
    font-weight: 400;
    line-height: 7.6rem;
    color: #ffffff;
    text-align: center;
  }
  .btn52 {
    margin-top: 4.4rem;
    width: 38rem;
    height: 7.6rem;
    border-radius: 9999px;
    background: rgb(231, 231, 231);
    font-size: 2rem;
    font-weight: 400;
    line-height: 7.6rem;
    color: #101213;
    text-align: center;
  }
  img {
    width: 100%;
    height: 20rem;
    margin-bottom: 5rem;
    object-fit: cover;
    border-radius: 5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .fivth {
    margin-top: 4rem;
    padding: 0 2rem;
    .inner {
      padding: 1rem 1rem;
      border-radius: 1rem;
      h1 {
        margin-top: 0;
        font-size: 1.6rem;
        line-height: 2.3rem;
      }
      .btn51 {
        margin-top: 2rem;
        height: unset;
        width: 100%;
        padding: 1rem 1rem;
        font-size: 1rem;
        line-height: 1rem;
      }
      .btn52 {
        margin-top: 2rem;
        height: unset;
        width: 100%;
        padding: 1rem 1rem;
        font-size: 1rem;
        line-height: 1rem;
      }
    }
    .box53 {
      margin-top: 2rem;
      img {
        margin-bottom: 1rem;
        width: 100%;
        height: 7rem;
        border-radius: 2rem;
        object-fit: cover;
      }
    }
  }
}

.sixth {
  margin-top: 15rem;
  padding: 0 27rem;
  .box61 {
    margin-top: 9rem;
    padding: 0 11.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    span {
      margin-bottom: 5rem;
      width: 25%;
      padding: 0 5rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .sixth {
    margin-top: 4rem;
    padding: 0 2rem;
    .box61 {
      margin-top: 0;
      padding: 0;
      span {
        width: 50%;
        height: 5rem;
        padding: 0 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.seventh {
  margin-top: 15rem;
  height: 100rem;
  padding: 27rem 19rem;
  position: relative;
  overflow: hidden;
  h1 {
    margin-left: 14rem;
    font-size: 3.6rem;
    font-weight: 700;
    color: #fff;
    line-height: 5.2rem;
  }
  .btn1 {
    margin-top: 18rem;
    margin-left: 14rem;
    padding: 0 5.4rem;
    width: 25rem;
    height: 6.4rem;
    background: linear-gradient(
      161.7deg,
      rgb(7, 247, 189) 8.15%,
      rgb(7, 247, 239) 67.51%
    );
    border-radius: 9999px;
    font-size: 2rem;
    font-weight: 400;
    line-height: 6.4rem;
    text-align: center;
    color: rgb(38, 35, 35);
  }
  .img7a {
    position: absolute;
    height: 70rem;
    width: 70rem;
    z-index: 1;
    object-fit: contain;
    top: 10rem;
    right: 32.5rem;
  }
}

@media screen and (max-width: 768px) {
  .seventh {
    margin-top: 4rem;
    padding: 0 2rem 4rem;
    height: unset;
    h1 {
      margin-left: 0;
      margin-top: 0;
      font-size: 1.6rem;
      line-height: 2.3rem;
    }
    .btn1 {
      margin-left: 0;
      margin-top: 2rem;
      height: unset;
      width: 100%;
      padding: 1rem 1rem;
      font-size: 1rem;
      line-height: 1rem;
    }
  }
}

.bg2 {
  position: absolute;
  bottom: -30rem;
  left: 0;
  width: 100%;
  z-index: -1;
  .img7b {
    width: 100%;
  }
  .img7c {
    position: absolute;
    bottom: -15rem;
    right: 3rem;
    width: 200rem;
  }
}

.bg3 {
  position: absolute;
  top: 133rem;
  left: -50rem;
  z-index: -1;
  width: 150rem;
  img {
    width: 100%;
  }
}

.bg4 {
  position: absolute;
  top: 260rem;
  right: -50rem;
  z-index: -1;
  width: 150rem;
  img {
    width: 100%;
  }
}
</style>
